/*global $:false */
(function () {
   'use strict';

	var loadingHtml = '<span class="loading">Loading</span>';

	var chewie = {

		changeTab: function (e) {
			e.preventDefault();
			var href=$(this).attr('href');
			$('dl.tabs a[href="'+href+'"]').trigger('click');
			console.log(href);
		},

		markReminder: function (e) {
			e.preventDefault();
			var that=this;
			$.get($(this).attr('href'), function (data) {
				var reminder = $(that).closest('.reminder');
				var reminderList = $(that).closest('.reminderList');
				// remove from reminder list
				if (reminderList.length) {
					reminder.remove();
				}
				// update note
				else {
					$(reminder).addClass('done');
					$(reminder).append('<i class="fa fa-check"></i>');
					$(that).remove();
				}
			});
		},

		saveNoteInprogress: false,

		changeNoteRating: function (e) {
			var that=this;
			var el=$(this).closest('.rating');
			var form=$(this).closest('form');
			var rating=$(this).attr('data-rating');
			$(el).attr('class', 'rating active active'+rating);
			$('[name="rating"]', form).val(rating);
		},

		changeNoteType: function (e) {
			e.preventDefault();
			var that=this;
			var form=$(this).closest('form');
			var el=$(this).closest('.type');
			var label=$('.text', el);
			var icon=$('.dropdown i.fa', el);
			var type=$(this).attr('data-type');
			if (type=="none") {
				$(label).text('Type');
				$(icon).attr('class', 'fa fa-question-circle');
				$('[name="note_type"]', form).val('');
				$('[name="contact_id"]', form).val('');
				$('[name="contact_input"]', form).val('');
				$(el).removeClass('active');
			}
			else {
				$(label).text(type);
				$(icon).attr('class', $('i', that).attr('class'));
				$('[name="note_type"]', form).val(type);
				$('[name="contact_id"]', form).val('');
				$('[name="contact_input"]', form).val('');
				$(el).addClass('active');
				$('[name="contact_input"]', form).focus();
			}
		},

		changeNoteReminder: function (e) {
			var form=$(this).closest('form');
			var el = $(this).closest('.reminder');
			var val=$(this).val();
			if (val.length>0) {
				$(el).addClass('active');
			}
			else {
				$(el).removeClass('active');
			}
		},

		changeNoteLead: function (e) {
			var form=$(this).closest('form');
			var lead = $(this).closest('.lead');
			if ($(this).is(':checked')) {
				$(lead).addClass('active');
			}
			else {
				$(lead).removeClass('active');
			}
		},
		
		saveNote: function (e) {
			e.preventDefault();
			if (chewie.saveNoteInprogress) {
				return false;
			}
			chewie.saveNoteInprogress=true;
			var form = this;
			var textEl = $('textarea[name="text"]', form);
			var text = $(textEl).val();
			var linkType = $('input[name="link_type"]', form).val();
			var linkId = $('input[name="link_id"]', form).val();
			var noteType = $('input[name="note_type"]', form);
			var rating = $('input[name="rating"]', form);
			var reminder = $('input[name="reminder"]', form);
			var reminderUser = $('select[name="reminder_user_id"]', form);
			var lead = $('input[name="lead"]', form);
			var contactId = $('input[name="contact_id"]', form);
			var contactInput = $('input[name="contact_input"]', form);
			var postData={ 
					text: text, 
					link_type: linkType,
					link_id: linkId,
					note_type: $(noteType).val(),
					rating: $(rating).val(),
					reminder: $(reminder).val(),
					reminder_user_id: $(reminderUser).val(),
					lead: $(lead).is(':checked') ? 1 : 0,
					contact_id: $(contactId).val(),
					contact_input: $(contactInput).val(),
				};
			$.post("/note/create", postData, function (data) {
				$(textEl).val('');
				$(noteType).val('');
				$(rating).val('');
				$(reminder).val('');
				$(reminderUser).val('');
				$(lead).val('');
				$(contactId).val('');
				$(contactInput).val('');
				$.ajax({
				  url: '/note/form',
				  data: { type: linkType, id: linkId, desc: $('[name="desc"]', form).val() },
				  success: function (data) {
						var newNoteListHtml=$('.note-list', data).html();
						var noteListForm=$(form).closest('.noteListForm');
						$('.note-list', noteListForm).html(newNoteListHtml);
						chewie.saveNoteInprogress=false;
						$('.active', form).removeClass('active');
					},
				  dataType: 'html'
				});
			});
		},

		initNoteForm: function () {
			$('.noteListForm:not(.init)').submit(chewie.saveNote);
			$('.noteListForm:not(.init) .lead input').change(chewie.changeNoteLead);
			$('.noteListForm:not(.init) .reminder input').change(chewie.changeNoteReminder);
			$('.noteListForm:not(.init) .type .f-dropdown a').click(chewie.changeNoteType);
			$('.noteListForm:not(.init) .rating i').click(chewie.changeNoteRating);
			$('.noteListForm:not(.init)').addClass('init');
		},

		deleteRow: function (e) {
			console.log('foo');
			var c = confirm('Are you sure you want to delete this record?');
			var $that = $(this);
			e.preventDefault();

			if (!c)
				return false;

			$.post($that.attr('href'), [], function (data) {
				$that.closest('tr').remove();
			});
		},

		remember: function (e) {
			var $this = $(this);
			var key = $this.attr('data-remember-key');
			var val = $this.attr('data-remember-value');
			$.post('/user/remember', {key: key, value: val});
		}
	}

	// Applied globally on all textareas with the "autoExpand" class
	$(document)
	    .one('focus.autoExpand', 'textarea.autoExpand', function(){
	        var savedValue = this.value;
	        this.value = '';
	        this.baseScrollHeight = this.scrollHeight;
	        this.value = savedValue;
	    })
	    .on('input.autoExpand', 'textarea.autoExpand', function(){
	        var minRows = this.getAttribute('data-min-rows')|0, rows;
	        this.rows = minRows;
	        rows = Math.ceil((this.scrollHeight - this.baseScrollHeight) / 17);
	        this.rows = minRows + rows;
	    	console.log(this.scrollHeight, this.baseScrollHeight);
	    });

	function openItemModal(gridSelector, url) {
		$("#itemModel").html(loadingHtml);
		$("#itemModel").foundation("reveal","open");
		$.get(url, {ajax: "true"}, function (data) {
			$("#itemModel").html(data);
		});
		$(gridSelector+' .selected').removeClass('selected');
	}

	$(function () {

		$('[data-remember-key]').click(chewie.remember);
		$('#contacts-grid-all .delete').click(chewie.deleteRow);
		$('.change-tab').click(chewie.changeTab);
		$('.markReminder').click(chewie.markReminder);

		autosize($('textarea.autosize'));

		chewie.initNoteForm();

		if ($('#reconcile-grid').length) {
			$('#notesModal').foundation('reveal', {
				opened: function(){
					$('#notesModal .noteListForm').submit(chewie.saveNote);
				},
			});
			$('#reconcile-grid tbody tr').each(function (x) {
				var domain=$('td.domain', this).text().trim();
				var select=$('.assignHost select', this);
				$('.assignHost option', this).each(function (y) {
					if ($(this).text().trim()==domain && !$('#hostingAssigned'+$(this).attr('value')).length) {
						$(select).val($(this).attr('value'));
					}
				});
			});
		}

		$(document).foundation();

		Pizza.init(document.body);

		$('table .filters input').attr('placeholder', 'Filter...');

		/*$('table a.sort-link').click(function(e){
			var icon = $(this).attr('href').indexOf('desc')>-1 ? "down" : "up";
			$('i.icon', $(this).parents('thead')[0]).remove();
			$(this).append('<i class="icon fi-arrow-'+icon+'"></i>');
		});*/

		$('#notify-nav').click(function(e){
			var that=this;
			var notifications=$('#notifications');
			if ($(that).hasClass('unread'))
			{
				$.getJSON('/user/readNotifications',function(data){
					if (!data.success)
						return;
					$(that).removeClass('unread');
					$('.count', that).text('0');
				});
			}
			if ($(notifications).hasClass('active'))
				$(notifications).slideUp().removeClass('active');
			else
				$(notifications).slideDown().addClass('active');
			e.preventDefault();
		});

		// timesheet panel
		var timesheetPanel=$('#timesheetPanel');
		$('.title',timesheetPanel).on('click',function(e){
			if ($(timesheetPanel).hasClass('collapsed'))
			{
				$('#timesheetPanel').removeClass('collapsed').addClass('expanded');
				e.preventDefault();
			}
		});
		$('.close',timesheetPanel).on('click',function(e){
			$(timesheetPanel).removeClass('expanded').addClass('collapsed');
		});
		var tsLoading=false;
		$('.punch',timesheetPanel).on('click',function(e){
			if (tsLoading)
			{
				e.preventDefault();
				return false;
			}
			tsLoading=true;
			var that=this;
			var listItemEl=$(that).parent();
			var listEl=$(listItemEl).parent();
			var activeEl=$('.active',timesheetPanel);
			$('.punch i',listItemEl)
				.removeClass('fi-pause')
				.removeClass('fi-play')
				.addClass('fi-loop');
			$.getJSON(
				$(that).attr('href'),
				{},
				function (data) {
					if (!data.status)
						return false;
					stopTs();
					console.log(data.status);
					$('.punch i',listItemEl)
						.removeClass('fi-loop');
					if (activeEl.length)
					{
						console.log($('.title',activeEl).text());
						$(activeEl).removeClass('active');
						$('.punch i',activeEl).addClass('fi-play');
					}
					if (data.status=='on')
					{
						$(listItemEl).prependTo(listEl).addClass('active');
						$('.punch i',listItemEl).addClass('fi-pause');
						initTs();
					}
					tsLoading=false;
				}
			);
			e.preventDefault();
		});
		initTs();

		// progress bar animations
		var progMeters=$('.progress .meter');
		var progDelay=500/progMeters.length;
		$(progMeters).each(function(x){
			var that=this;
			setTimeout(function(){
				$(that).width($(that).attr('data-width'));
			},progDelay*x);
		});
	});
}());

// timesheet counter
var activeTsEl;
var tsSec = 0;
var tsMin = 0;
var tsHrs = 0;
var tsIntSec;
var tsIsOn = 0;
var tsSecEl;
var tsMinEl;
var tsHrsEl;
function pad(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}
function initTs()
{
	activeTsEl=$('#timesheetPanel li.active');
	if (activeTsEl)
	{
		tsSecEl=$('.sec',activeTsEl);
		tsMinEl=$('.min',activeTsEl);
		tsHrsEl=$('.hrs',activeTsEl);
		tsSec=$(tsSecEl).text()*1;
		tsMin=$(tsMinEl).text()*1;
		tsHrs=$(tsHrsEl).text()*1;
		playTs();
	}
}
function tsCount()
{
	tsSec = tsSec + 1;
	tsIntSec = setTimeout("tsCount()", 1000);
	$(tsSecEl).text(pad(tsSec,2));
	$(tsMinEl).text(pad(tsMin,2));
	$(tsHrsEl).text(pad(tsHrs,2));
	if (tsSec >= 60)
	{
		tsMin = Math.floor (tsSec / 60) + tsMin;
		tsSec %= 60;
	}
	if (tsMin >= 60)
	{
		tsHrs = Math.floor (tsMin / 60) + tsHrs;
		tsMin %= 60;
	}
}
function playTs()
{
	if ( ! tsIsOn)
	{
		tsIsOn = 1;
		tsCount();
	}
}
function stopTs()
{
	clearTimeout(tsIntSec);
	tsIsOn = 0;
}